import React from "react";
import * as styles from "./partneriai.module.css"

const Partneriai = ({ header, partneriai }) => {
    return <div className={styles.partnersContainer}>
        <h3 style={{ textAlign: 'center', paddingTop: '20px', marginTop: '0' }}>{header}</h3>
        <div className={styles.partners}>
            {partneriai.map((p, i) =>
                <div className={styles.imageContainer} key={i} >
                    <a href={p.nuoroda} target="_blank">
                        <img className={styles.image} src={`${process.env.GATSBY_API_URL}${p.paveiksliukas[0].url}`} />
                    </a>
                </div>
            )}
        </div>
    </div>
};

export default Partneriai;
