import React from "react";
import * as styles from "../pages/index.module.css"

export default function Tournament ({date, name}) {
    return (
        <div className={styles.latestTournaments}>
            <div className={styles.tournamentDate}>
                { date }
            </div>
            <div className={styles.tournamentName}>
                { name }
            </div>
        </div>
    )
}