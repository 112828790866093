import React from "react"
import Tournament from "./tournament"

const inFuture = (date) => {
    return date.setHours(0,0,0,0) >= new Date().setHours(0,0,0,0)
};

export default function LatestTournaments({tournaments}) {
    tournaments = tournaments.filter(t => inFuture(new Date(t.pabaiga))) || [];
    tournaments = tournaments.slice(0, 5);
    return (
        <div className="latestTournaments">
            <h3 style={{ textAlign: 'center', paddingTop: '30px', marginTop: '0', fontSize: '19px' }}>Artimiausios varžybos</h3>
            {tournaments.map((t,i) => <Tournament key={i} name={t.pavadinimas} date={`${t.pradzia} - ${t.pabaiga}`} />)}
        </div>
    )
}

