import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout/layout";
import Articles from "../components/articles/articles";
import "../assets/css/main.css";
import Partneriai from "../components/partneriai/partneriai";
import * as styles from "./index.module.css"
import LatestTournaments from "../components/latest-tournaments"
import StyledButton from "../components/StyledButton";
import { MdKeyboardArrowDown } from "@react-icons/all-files/md/MdKeyboardArrowDown"
import SEO from "../components/layout/seo";
import BasicLayout from "../components/BasicLayout";
import Header from "../components/Header";

const IndexPage = () => {
  const data = useStaticQuery(query);
  return (
    <Layout seo={data.strapiHomepage.seo}>
      <SEO
        seo={{
          metaTitle: "gimnastika.lt | Pagrindinis",
        }}
      />
      <div className={styles.imageContainer}>
        <div className={styles.imagesTextContainer}>
          <h1 className={styles.imagesSectionHeading} >
            {data.strapiHomepage.nuotraukos_tekstas}
          </h1>
          <a href="#pradzia" className={styles.imagesSectionButton}>
            <MdKeyboardArrowDown size={35} style={{ zIndex: '1000' }} color={'white'} />
          </a>
        </div>
        <img src={data.strapiHomepage.pagrindine_nuotrauka.publicURL} className={styles.backgroundImage} />
      </div>

      <Header id="pradzia" name="Paskutinės naujienos" />

      <BasicLayout
        left={
          <>
            <Articles articles={data.allStrapiArticle.edges} />
            <StyledButton to="/naujienos" name="Visos naujienos" />
          </>}
        right={
          <>
            <div style={{ marginBottom: '1rem' }}>
              <LatestTournaments tournaments={data.allStrapiArtimiausiosVarzybos.nodes} />
            </div>
            {data.strapiHomepage.partneriai &&
              <div style={{ marginBottom: '1rem' }}>
                <Partneriai header="Partneriai" partneriai={data.strapiHomepage.partneriai} />
              </div>
            }
            {data.strapiHomepage.remejai &&
              <div style={{ marginBottom: '1rem' }}>
                <Partneriai header="Rėmėjai" partneriai={data.strapiHomepage.remejai} />
              </div>}
          </>
        } />
    </Layout>
  );
};

// remejai {
//   nuoroda
//   paveiksliukas {
//     url
//   }
// }
const query = graphql`
  query {
    strapiHomepage {
      partneriai {
        nuoroda
        paveiksliukas {
          url
        }
      }

      nuotraukos_tekstas
      pagrindine_nuotrauka {
        publicURL
      }    
    }
    allStrapiArticle(limit: 10 sort: {fields: publishedAt, order: DESC}) {
      edges {
        node {
          strapiId
          slug
          title
          publishedAt(formatString: "LL", locale: "lt")
          category {
            name
          }
          image {
            childImageSharp {
              fixed(width: 700, height: 500) {
                src
              }
            }
          }
        }
      }
    }
    allStrapiArtimiausiosVarzybos(sort: {fields: pradzia, order: ASC}) {
      nodes {
        kada:pradzia(fromNow: true, locale: "lt") 
        pradzia
        pabaiga
        pavadinimas
      }
    }
  }
`;


export default IndexPage;
